import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastOptions } from 'react-toastify/dist/types';

export const Toaster = () => {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme={'colored'}
    />
  );
};

export const notify = (type: string, message: string, autoClose?: number) => {
  const defaultOptions = {
    position: 'bottom-right',
    autoClose: autoClose ? autoClose : 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  switch (type) {
    case 'info':
      toast.info(message, defaultOptions as ToastOptions);
      break;
    case 'success':
      toast.success(message, defaultOptions as ToastOptions);
      break;
    case 'warning':
      toast.warn(message, defaultOptions as ToastOptions);
      break;
    case 'error':
      toast.error(message, defaultOptions as ToastOptions);
      break;
    default:
      toast(message, defaultOptions as ToastOptions);
  }
};
